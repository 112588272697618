<template>
    <div class="wrapper -login">


        <div class="contenedor-formulario -recuperar-pass column-center">

            <div class="contenedor-logo">
                <img src="/img/logo-uc3m-alumni-dark.png" alt="Logo u3cm">
            </div>

            <ValidationObserver ref="form" v-slot="{failed,valid}">

                <form @submit.prevent="validate" class="formulario column-center" :valid="valid" :failed="failed">

                    <div class="contenedor-input">
                        <ValidationProvider rules="required|email" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="">Email</label>
                            <input type="text" :class="classes" v-model="data.email" name="email" placeholder="Email">
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-input">
                        <ValidationProvider :rules="{required:true,regex: /^(?=.*\d)(?=.*[\u0021\u003F\u0023\u0040\u0024\u0025\u0026\u0028\u0029\u002B\u002D\u002F\u002A\u003D\u005B\u005D\u007B\u007D\u005F\u002E\u003A\u003B])(?=.*[A-Z])(?=.*[a-z])\S{8,30}$/}" v-slot="{ errors,ariaInput,ariaMsg,classes }"
                            vid="password">
                            <label for="email">Contraseña*<popup-info :data="'contrasena'">
                            </popup-info></label>
                            <input type="password" :class="classes" v-model="data.password" name="password">
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div class="contenedor-input">
                        <ValidationProvider :rules="{required:true,confirmed:'password',regex: /^(?=.*\d)(?=.*[\u0021\u003F\u0023\u0040\u0024\u0025\u0026\u0028\u0029\u002B\u002D\u002F\u002A\u003D\u005B\u005D\u007B\u007D\u005F\u002E\u003A\u003B])(?=.*[A-Z])(?=.*[a-z])\S{8,30}$/}"
                            v-slot="{ errors,ariaInput,ariaMsg,classes }">
                            <label for="email">Repetir contraseña*</label>
                            <input type="password" :class="classes" v-model="data.password_confirmation" name="password"
                                @keyup.enter="validate">
                            <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="contenedor-boton">
                        <a @click="validate" class="btn -skull-blue" :loading="loading">Restablecer contraseña</a>
                    </div>

                </form>
            </ValidationObserver>

        </div>

    </div>
</template>

<script>

    import router from '@/router';

    import {mapActions} from 'vuex';

    export default {
        name: 'reset-password',
        mounted(){
           this.data.type = (this.$route.params.id == 'mentee') ? 'mentee' : 'profesor';
           this.data.token = this.$route.query.token;
        },
        data: () => ({
            data: {
                password: '',
                password_confirmation: '',
                type : '',
                token : '',
                email : ''
            },
            loading : false
        }),
        methods: {
            ...mapActions(['sendPass']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.sendPass(this.data).then(r => {
                            if (r) {
                                this.loading = false;
                                setTimeout(() => {
                                    router.push({ name: 'login' });
                                }, 3000);
                            }
                        });
                    }
                });
            }
        }
    }
</script>